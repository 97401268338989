<template>
  <div id="login "  >

    <div class="columns is-mobile is-centered m-5 ">
      <div class="column is-4 is-primary form-box">
        <h4 class="has-text-centered login-heading"> LOGIN </h4>
        <form >
       <b-field label="Email"
            :label-position="labelPosition">
            <b-input type="email" size="is-medium" v-model="input.username"
                value="" name="username"
                maxlength="30">
            </b-input>
        </b-field>

         <b-field label="Password" :label-position="labelPosition">
            <b-input type="password" size="is-medium" v-model="input.password"
                value="iwantmytreasure" name="password"
                password-reveal>
            </b-input>
        </b-field>
        <div class="has-text-centered mt-5">
           <b-button class="mt-3 " size="is-medium" expanded type="is-danger" outlined v-on:click="login()" >
                Login
            </b-button>
            </div>
        </form>
          <div class="has-text-centered mt-5">
         Not Remember? <router-link to="/" class="forget-pw">  Forget Password </router-link>
          </div>

        <p class="has-text-centered mt-2 "> or login with </p>
        <div class="has-text-centered mt-5">
          <router-link to="/" class="login-form-fb"> <i class="fa fa-facebook-official" aria-hidden="true"></i> Facebook </router-link> <router-link to="/" class="login-form-google"> <i class="fa fa-google" aria-hidden="true"></i> Google </router-link>
        </div>


      </div><!-- Login class -->
    </div>

    </div>
</template>

<script>

export default {
  name: 'Login',
  data(){
    return{
       labelPosition: 'on-border',
       input : {
         username : "",
         password : ""
       }
    }
  },
  methods:{
    login(){
      this.$store.dispatch('login', {email:this.input.username, password: this.input.password});
    } /// login
  } ////
}
</script>

